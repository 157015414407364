import React from 'react';
import { NavLink } from 'react-router-dom';
import { hostname } from '../../constants/urls';

const CollegeCarouselItem = ({ college }) => {
    return (<>
        <div className="item">
            <div className="card cover-image bg-background-color place-tour-card place-tour-card2 mb-0" data-image-src={hostname + college.ProfilePic}>
                <div className="card-body px-3 py-5 text-white content-text place-tour">
                    <h3 className="mt-2 mb-0 ts-5">{college.Name}</h3>
                    <p className="fs-14 mt-2 leading-normal"></p>
                    <small className="text-white"><i className="fe fe-map-pin"></i>{college.Country}<i className="ml-3 fe fe-eye"></i>{college.Courses ? college.Courses.length : 0} Courses</small>
                    <NavLink to={"/details/college/"+encodeURIComponent(college.Name)+"/"+college.Id} className="btn btn-sm btn-secondary px-5">Apply</NavLink>
                </div>
            </div>
        </div>
    </>);
}

export default CollegeCarouselItem;