import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Helper from '../../apis/helper';
import Config from '../../config';
import './header.css';

const Header = ({detailsPage}) => {
	const redirectToAdminApp = (path) => {
		Helper.redirectToAdminApp(path);
	}
    useEffect(()=>{
        setTimeout(window.initSticky,50);
		window.initHoriMenu();
    },[]);
    return ( <React.Fragment>
		<div className={"header-main " + (detailsPage ? "header-style03" : "header-absolute")}>

			<div className="sticky">
				<div className="horizontal-header clearfix ">
					<div className="container">
						<a id="horizontal-navtoggle" className="animated-arrow"><span></span></a>
						<span className="smllogo"><NavLink to="/"><img src="../../assets/images/brand/favicon.png" alt=""/>{Config.appName}</NavLink></span>
						<a href="add-post.html" className="callusbtn"><i className="fe fe-plus-circle" aria-hidden="true"></i></a>
					</div>
				</div>
			</div>

			<div className="horizontal-main header-transparent clearfix py-lg-3">
				<div className="horizontal-mainwrapper container clearfix">
					<div className="desktoplogo">
						<NavLink to="/"><img src={"/assets/images/brand/" + (detailsPage ? "favicon.png" : "favicon-white.png")} alt=""/>{Config.appName}</NavLink>
					</div>
					<div className="desktoplogo-1">
						<NavLink to="/"><img src="../../assets/images/brand/favicon.png" alt=""/>{Config.appName}</NavLink>
					</div>
					<nav className="horizontalMenu clearfix d-md-flex">
						<ul className="horizontalMenu-list">
							<li><a href="javascript:void(0)" onClick={()=>{redirectToAdminApp('/register/student')}}>Applicants</a></li>
                            <li><a href="javascript:void(0)" onClick={()=>{redirectToAdminApp('/register/agent')}}>Partner Agents</a></li>
                            <li><a href="javascript:void(0)" onClick={()=>{redirectToAdminApp('/register/college')}}>Institutions</a></li>
                            <li className="mr-4 mb-2">
								<span><a className="btn btn-secondary" href="javascript:void(0)" onClick={()=>{redirectToAdminApp('/register/login')}}><i className="fe fe-user"></i> Log In</a></span>
							</li>
                            <li className="">
								<span><a className="btn btn-secondary" href="javascript:void(0)" onClick={()=>{redirectToAdminApp('/register/student')}}><i className="fe fe-user-plus"></i> Register</a></span>
							</li>
						</ul>
					</nav>
				</div>
				<div className="body-progress-container">
					<div className="progress-bar progress-bar-striped progress-bar-animated bg-success" id="myBar"></div>
				</div>
			</div>
		</div>
    </React.Fragment> );
}
 
export default Header;