import React, { useEffect } from 'react';
import ServiceItem from './serviceItem';

const ServicesCarousel = ({products}) => {
    useEffect(()=>{
        var owl = window.$('.owl-carousel-icons2-ser');
        owl.owlCarousel({
            padding: 20,
            loop: true,
            nav: true,
            autoplay:false,
            dots: false,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1300: {
                    items: 4,
                    nav: true
                }
            }
        })
    }, []);
    setTimeout(()=>{
        window.$(".owl-carousel-icons2-ser img").css({'height':document.querySelector('.owl-carousel-icons2-ser img').offsetWidth+'px'});
    }, 500);
    return ( <section className="sptb bg-white">
    <div className="container">
        <div className="section-title center-block text-center">
            <span className="heading-style text-secondary d-none">Best Top</span>
            <h2>Visa Services</h2>
            <p>It's easy, just search select & get visa</p>
        </div>
        <div className="owl-carousel owl-carousel-icons2-ser">
            {products.map((prod, ind)=>{
                return <ServiceItem key={ind} product={prod}></ServiceItem>
            })}
        </div>
    </div>
</section> );
}
 
export default ServicesCarousel;