export const hostname = "";
//  window.location.hostname === "localhost" ? "" : (window.location.hostname.indexOf("visa24.io")>=0 ? "http://app.visa24.io" : "http://app.admission24.com.au");
//http://app.admission24.com.au
//http://app.visa24.io
export const adminAppUrl = "/admin";
const URLs = {
  colleges: {
    list: hostname + "/api/home/GetColleges",
    get: hostname + "/api/home/GetCollege/",
  },
  products: {
    list: hostname + "/api/home/GetProducts",
    get: hostname + "/api/home/GetProduct/",
  },
  attachment: {
    view: hostname + "/api/attachment/view/",
  },
  home: {
    search: hostname + "/api/home/GetSearchResults",
    categories: hostname + "/api/home/getCategories",
    blogs: hostname + "/api/blog/GetBlogs",
  },
};

export default URLs;
