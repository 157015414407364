import React from 'react';
import { NavLink } from 'react-router-dom';
import { hostname } from '../../constants/urls';

const ServiceItem = ({product}) => {
    return ( <div className="item">
    <div className="item-card overflow-hidden mb-0">
        <div className="item-card-desc">
            <NavLink to={"/details/product/"+encodeURIComponent(product.Name)+"/"+product.Id}></NavLink>
            <div className="card text-center overflow-hidden mb-0">
                <div className="card-img">
                    <img src={hostname + product.ProfilePic} alt="img" className="cover-image" />
                </div>
                <div className="item-card-text text-left">
                    <div className="star-ratings start-ratings-main clearfix d-none">
                        <div className="stars stars-example-fontawesome stars-example-fontawesome-sm text-left">
                            <select className="example-fontawesome" name="rating" autocomplete="off">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4" selected>4</option>
                                <option value="5">5</option>
                            </select>
                        </div>
                    </div>
                    <h4 className="mb-2">{product.Name}</h4>
                    <small className="text-white"><i className="fe fe-map-pin"></i> {product.Country} </small>
                </div>
            </div>
        </div>
    </div>
</div> );
}
 
export default ServiceItem;