import Header from "../header/header";
import Slider from "../slider/slider";

const Terms = () => {
    return ( <>
        <Header detailsPage={true}/>
        <div className="container p-5">
                <div className="row">
                  <div className="col">
                    <h1 className="">Terms and Conditions</h1>
                  </div>
                  </div>
                  <div className="row">
                    <div className="col">
                    <p className="content">Please read these Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) carefully before using the
immigration visa website visa 24 operated by [Your Company Name] (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).
<br/>
<br/>
<h4>Acceptance of Terms</h4>
By accessing or using the Website visa 24, you agree to be bound by these Terms and Conditions. If you
disagree with any part of the Terms, then you may not access the visa 24.
<br/>
<br/>
<h4>Website Use</h4>
a. The information provided on the visa 24 is for general informational purposes only. It does not
constitute legal advice or guidance specific to your individual circumstances. You should seek
professional advice before making any decisions based on the information provided.
<br/>
b. We strive to keep the Website accurate and up to date. However, we make no warranties or
representations of any kind, express or implied, about the completeness, accuracy, reliability, suitability,
or availability with respect to the visa 24 or the information, products, services, or related graphics
contained on the visa 24 for any purpose.
<br/>
c. Your use of any information or materials on the Website is entirely at your own risk. We shall not be
liable for any loss or damage arising from your reliance on the information provided on the Website.
<br/>
d. The Website may contain links to third-party websites that are not owned or controlled by us. We
have no control over the content, privacy policies, or practices of any third-party websites. We do not
endorse or assume any responsibility for the content or practices of these websites. Your use of such
websites is at your own risk.

<br/>
<br/>
<h4>User Conduct</h4>
a. When using the Website, you agree not to engage in any unlawful, offensive, or harmful activities.
You shall not violate any applicable laws, regulations, or third-party rights.<br/>
b. You shall not attempt to gain unauthorized access to any portion of the Website, including its
systems, servers, or networks.
<br/>
c. You shall not interfere with or disrupt the functionality or security of the Website or its associated
servers or networks.

<br/>
<br/>
<h4>Intellectual Property</h4>
a. The content on the Website, including text, graphics, logos, images, videos, and software, is the
intellectual property of [Your Company Name] and is protected by applicable intellectual property laws.<br/>
b. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create
derivative works from, transfer, or sell any information or content obtained from the Website without
our prior written consent.

<br/>
<br/>
<h4>Privacy Policy</h4>
a. We respect your privacy and are committed to protecting your personal information. Please review
our Privacy Policy to understand how we collect, use, and safeguard your information.<br/>
b. By using the Website, you consent to the collection and use of your information as outlined in our
Privacy Policy.

<br/>
<br/>
<h4>Termination</h4>
We reserve the right to terminate or suspend access to the Website at any time, without prior notice or
liability, for any reason whatsoever, including, without limitation, if you breach these Terms and
Conditions.

<br/>
<br/>
<h4>Governing Law</h4>
These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your
Country], without regard to its conflict of law provisions.

<br/>
<br/>
<h4>Changes to the Terms and Conditions</h4>
We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any
time. The updated version will be effective immediately upon posting on the Website. Your continued
use of the Website after any changes constitutes your acceptance of the revised Terms and Conditions.

<br/>
<br/>
<h4>Contact Us</h4>
If you have any questions about these Terms and Conditions, please contact us at [contact email].

By using the immigration visa website, you acknowledge that you have read, understood, and agree to
be bound by these Terms and Conditions.</p>
                    </div>
                  </div>
                  </div>
        
    </> );
}
 
export default Terms;