import { useEffect, useState } from "react";
import HomeService from "../../apis/homeService";
import BlogItem from "./blogItem";

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    HomeService.getBlogs().then((res) => {
      setBlogs(res.Data || []);
    });
  }, []);
  if (blogs.length == 0) return null;
  return (
    <section className="sptb bg-white">
      <div className="container">
        <div className="section-title center-block text-center">
          <span className="heading-style text-secondary">New</span>
          <h2>Blog News</h2>
          <p className="d-none">
            Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
            vehicula
          </p>
        </div>
        <div className="row">
          {blogs.map((blog) => {
            return <BlogItem key={blog.Id} blog={blog}></BlogItem>;
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
