import { adminAppUrl } from "../constants/urls";

export default class Helper {
    static smoothScroll(selector) {
        document.querySelector(selector) && document.querySelector(selector).scrollIntoView({
            behavior: 'smooth'
        });
    }

    static updateImageSources(selector = '.cover-image') {
        window.$(selector).each(function () {
            var attr = window.$(this).attr('data-image-src');
            if (typeof attr !== typeof undefined && attr !== false) {
                window.$(this).css('background', 'url(' + attr + ') center center');
            }
        });
    }

    static updateFlexListAndSelect2() {
        window.$('.flexdatalist').flexdatalist({
            selectionRequired: true,
            minLength: 1
        });
        setTimeout(() => {
            window.$('.select2-show-search:not(.select2-hidden-accessible)').select2({
                minimumResultsForSearch: '',
                placeholder: "Search"
            });
        }, 100);
    }

    static getValueFromObject(arr, searchVal, searchKey = 'name', getKey = 'id') {
        if (arr && arr instanceof Array && searchVal) {
            const res = arr.find(x => x[searchKey].toLowerCase() == searchVal.toLowerCase());
            return (res && res[getKey]) || '';
        }
        return '';
    }

    static getCountryCodeFromName(countryArr, name) {
        return this.getValueFromObject(countryArr, name);
    }

    static getCountryNameFromCode(countryArr, code) {
        return this.getValueFromObject(countryArr, code, 'id', 'name');
    }

    static redirectToAdminApp(path) {
        window.location.href = (window.location.origin.indexOf('localhost')>=0 ? window.location.origin.replace('3000', '4200') : adminAppUrl) + "/#" + path;
    }
}