import React, { useEffect } from 'react';


const RatedCountries = () => {
    const countryNames = ['USA', 'UK', 'NEWZEALAND', 'SINGAPORE', 'DUBAI', 'AUSTRALIA', 'RUSSIA', 'CANADA', 'FRANCE', 'THAILAND'];
    useEffect(()=>{
        var owl = window.$('.owl-carousel-icons-countries');
	owl.owlCarousel({
		padding: 20,
		loop: true,
		nav: true,
		autoplay:true,
		dots: false,
		lazyLoad: true,
		autoplayTimeout: 3500,
autoplayHoverPause: true,
transitionStyle: 'fade',
animateIn: 'fadeIn',
animateOut: 'fadeOut',
		responsive: {
			0: {
				items: 2
			},
			767: {
				items: 4
			},
			1300: {
				items: 6
			}
		}
	})
    });
    return ( <><section className="sptb bg-white pb-5 pt-0">
    <div className="container">
        <div className="section-title center-block text-center d-none">
            <span className="heading-style text-secondary">Top Most</span>
            <h2>Rated Countries</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
        </div>
        {/* <div className="row" id="container1">
            {countryNames.map((c, i)=>{
                return <div className={"col-xl-2 col-lg-3 col-md-3 col-sm-6" + (i==0 || i==5 ? ' offset-xl-1' : '') } key={i}>
                <div className="card bg-pink-transparent border-dark-transparent">
                    <a className="absolute-link2" href="#"></a>
                    <div className="card-body p-0">
                    <img src={"../../assets/custom/images/countries/"+c+".jpg"} alt="img" className="bg-pink-transparent ieh-100"/>
                    </div>
                </div>
            </div>
            })}
            
        </div> */}

        <div  class="owl-carousel owl-carousel-icons-countries">
        {countryNames.map((c, i)=>{
                return <div class="item">
                <div class="card mb-0">
                    <div class="card-body p-0">
                    <img src={"../../assets/custom/images/countries/"+c+".jpg"} alt="img" className="bg-pink-transparent ieh-100"/>
                    </div>
                </div>
            </div>
            })}
					
				</div>


    </div>
</section></> );
}
 
export default RatedCountries;