import axios from 'axios';

function setLoader(show) {
    var loader = document.getElementById('api-loader');
    if (loader) {
        loader.style.display='block';
        if (!show) {
            loader.style.display='none';
        }
    }
}

class Networker {
    static get(url, showLoader = true) {
        if (showLoader)
            setLoader(true);
        return axios.get(url).then((data) => {
            if (showLoader)
                setLoader(false);
            return data.data;
        }).catch((err) => {
            if (showLoader)
                setLoader(false);
            return err;
        });
    };

    static post(url, data, showLoader = true) {
        if (showLoader)
            setLoader(true);
        return axios.post(url, data).then((data) => {
            if (showLoader)
                setLoader(false);
            return data.data;
        }).catch((err) => {
            if (showLoader)
                setLoader(false);
            return err;
        });
    }

}

export default Networker;