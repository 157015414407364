import HomePage from "./components/homepage/homepage";
import { RecoilRoot } from "recoil";
import { HashRouter, Routes, Route } from "react-router-dom";
import ProductDetails from "./components/productDetails/productDetails";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import SearchResuts from "./components/searchResuts/searchResuts";
import BlogDetails from "./components/blogs/blogDetails";
import Privacy from "./components/privacy/privacy";
import Terms from "./components/terms/terms";

function App() {
  return (
    <RecoilRoot>
      <HashRouter>
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/details/:type/:name/:id" element={<ProductDetails />} />
          <Route path="/search/:from/:to/:purpose" element={<SearchResuts />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </HashRouter>
    </RecoilRoot>
  );
}

export default App;
