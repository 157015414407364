import React, { useState } from 'react';
import config from '../../config';
import './infoCarousel.css';

const InfoCarousel = () => {
    const [selected, setSelected] = useState('one');
    const selectInfo = (section) => {
        setSelected(section);
    }
    return (<>
        <section class="sptb bg-white">
            <div class="container">
                <div class="section-title center-block text-center d-none">
                    <span class="heading-style text-secondary">Find Your</span>
                    <h2>Favourite Destination</h2>
                    <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
                </div>
                <div>
                    <div class="items-gallery">
                        <div class="items-blog-tab text-center">
                            <div class="items-blog-tab-heading row mb-0">
                                <div class="col-12">
                                    <ul class="nav items-blog-tab-menu">
                                        <li class=""><a href="#tab-1" class="active show" data-toggle="tab">STUDENTS</a></li>
                                        <li><a href="#tab-2" data-toggle="tab" class="">PARTNER AGENTS</a></li>
                                        <li><a href="#tab-3" data-toggle="tab" class="">PARTNER INSTITUTIONS</a></li>
                                        <li><a href="#tab-4" data-toggle="tab" class="">VISA APPLICANTS</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab-1">
                                    <div class="row details-section">
                                        <div className='col-lg-6 description'>
                                            <h3 className='p-1'>Students</h3>
                                            <p className='p-1'>
                                                {config.isAdmission24 ? <>
                                                    Explore courses &amp; universities, get admission with one click no need to go anywhere just sit relax. Our experts assist you with everything. Our Fully online Hassle-free process tells you what is going on with your application in real time just simply login and check status anytime. Giving more power in your hands. Be your own agent, Be your own boss.
                                                </> : <>
                                                    Explore courses &amp; universities, get admission with one click and we apply your visa online no need to go anywhere just sit relax. Our qualifiedvisa experts assist you with everything you need to get your visa approved. Fully online Hassle-free process let you know what is going on with your visa application in real time just simply login and check status anytime. Giving more power in your hands be your own agent, Be your own boss.
                                                </>}
                                            </p>
                                        </div>
                                        <div className='col-lg-6 image'>
                                            <img src="/assets/custom/images/carousel/info/students.jpg" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-2">
                                    <div class="row details-section">
                                        <div className='col-lg-6 description'>
                                            <h3 className='p-1'>Partner Agents</h3>
                                            <p className='p-1'>
                                                You Get access to Best universities &amp; collages worldwide on this portal. our team of dedicated visa experts is ready to assist you always with every single application. We areproviding you best support, best commission and best peace of mind. Under one roof you find what you to grow your Business.Let us take care of everything &amp; Let’s Grow Together.
                                            </p>
                                        </div>
                                        <div className='col-lg-6 image'>
                                            <img src="/assets/custom/images/carousel/info/agents.jpg" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-3">
                                    <div class="row details-section">
                                        <div className='col-lg-6 description'>
                                            <h3 className='p-1'>Partner Institutions</h3>
                                            <p className='p-1'>
                                                {config.isAdmission24 ? <>
                                                    Be A part of our family and showcase yourself in front of future students. Our  App helps you to connect with quality students. We filter all applications before you get it, and you only receive quality students. We promote your presence on our marketing and social media channels.
                                                </> : <>
                                                    Be A part of our family and showcase yourself in front of future students. Our search website helps you to connect with quality students. We filter all application before you get it, and you only receive quality students. We promote your presence on our marketing and social media channels.
                                                </>}
                                            </p>
                                        </div>
                                        <div className='col-lg-6 image'>
                                            <img src="/assets/custom/images/carousel/info/uni.jpg" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane" id="tab-4">
                                    <div class="row details-section">
                                        <div className='col-lg-6 description'>
                                            <h3 className='p-1'>Visa Applicants</h3>
                                            <p className='p-1'>
                                                {config.isAdmission24 ? <>
                                                    Congrats, it’s very easy to apply for your study visa through our online platform. Just register your profile, provide all necessary info & upload supporting documents, that's it. Once you provide all info & necessary documents our qualified visa experts cross check and apply your visa within time.
                                                </> : <>
                                                    Our one stop visa shop, makes visa process fully and gest your visa approved faster. It’s simple create your profile and let us do everything till yo fly for your Dream detonation abroad. We call this process Apply, supply and fly, just apply through our portal supply supporting documents get your visa and fly, its easy.
                                                </>}
                                            </p>
                                        </div>
                                        <div className='col-lg-6 image'>
                                            <img src="/assets/custom/images/carousel/info/visa.jpg" alt="image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className='info-carousel bg-white d-none'>
            <ul className='action-buttons'>
                <li>
                    <a href="javascript:void(0)" onClick={() => { selectInfo('one') }} className={'option-button mr-3' + (selected === 'one' ? ' active' : '')}>STUDENTS</a>
                </li>
                <li>
                    <a href="javascript:void(0)" onClick={() => { selectInfo('two') }} className={'option-button mr-3' + (selected === 'two' ? ' active' : '')}>RECRUITMENT PARTNERS</a>
                </li>
                <li>
                    <a href="javascript:void(0)" onClick={() => { selectInfo('three') }} className={'option-button' + (selected === 'three' ? ' active' : '')}>PARTNER SCHOOLS</a>
                </li>
            </ul>
            <ul className='details'>
                <li className={'details-item' + (selected === 'one' ? '' : ' d-none')}>
                    <div className='details-section'>
                        <div className='row'>
                            <div className='col-lg-4 description'>
                                <h3 className='p-1'>Students</h3>
                                <p className='p-1'>We believe in your dreams and work hard to make them a reality. Get matched with and apply to programs and schools that align with your background, skills, and interests.</p>
                            </div>
                            <div className='col-lg-8 image'>
                                <img src="/assets/custom/images/carousel/info/students.jpg" alt="image" />
                            </div>
                        </div>
                    </div>
                </li>
                <li className={'details-item' + (selected === 'two' ? '' : ' d-none')}>
                    <div className='details-section'>
                        <div className='row'>
                            <div className='col-lg-4 description'>
                                <h3 className='p-1'>Recruitment Partners</h3>
                                <p className='p-1'>ApplyBoard is more than a platform—we are your trusted partner—here to help you do what you do best: help as many students as possible fulfill their international education dreams.</p>
                            </div>
                            <div className='col-lg-8 image'>
                                <img src="/assets/custom/images/carousel/info/agents.jpg" alt="image" />
                            </div>
                        </div>
                    </div>
                </li>
                <li className={'details-item' + (selected === 'three' ? '' : ' d-none')}>
                    <div className='details-section'>
                        <div className='row'>
                            <div className='col-lg-4 description'>
                                <h3 className='p-1'>Partner Schools</h3>
                                <p className='p-1'>Increase your global presence and the number of qualified students from a single, easy-to-use platform trusted by more than 1,500 institutions worldwide.</p>
                            </div>
                            <div className='col-lg-8 image'>
                                <img src="/assets/custom/images/InfoCarousel1.jpeg" alt="image" />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </section>
    </>);
}

export default InfoCarousel;