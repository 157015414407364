import React from 'react';
import Config from '../../config';
import './footer.css';
import { NavLink } from 'react-router-dom';

const Footer = () => {
	return (<>
		<section>
			<footer className="bg-dark-purple text-white">
				<div className="footer-main border-bottom">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-6 col-md-12">
								<img src="/assets/images/brand/favicon-white.png" alt="" className="footer-logo" /> <span className='footer-logo-text'>{Config.appName}</span>
								<p className="mb-1 mt-2">Welcome to India’s first fully online one stop shop for your all visa needs.</p>
								<h6 className="mb-2 mt-5">Social Icons</h6>
								<ul className="social-icons mb-0 mt-3">
									<li>
										<a className="social-icon" href="https://www.facebook.com/visa24app"><i className="fa fa-facebook text-white-50"></i></a>
									</li>
									<li>
										<a className="social-icon" href="https://twitter.com/visa24india"><i className="fa fa-twitter text-white-50"></i></a>
									</li>
									<li>
										<a className="social-icon" href="https://www.instagram.com/visa24.app"><i className="fa fa-instagram text-white-50"></i></a>
									</li>
									<li>
										<a className="social-icon" href="https://www.youtube.com/@visa24app"><i className="fa fa-youtube text-white-50"></i></a>
									</li>
									<li>
										<a className="social-icon" href="https://www.threads.net/@visa24.app"><i className="fa fa-ravelry text-white-50"></i></a>
									</li>
								</ul>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<ul className="list-unstyled mb-0 mt-3">
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Applicants</a></li>
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Institutions</a></li>
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Agents</a></li>
								</ul>
								<div className='mt-6'>
									<h6 className='mb-1'>Explore</h6>
									<ul className="list-unstyled mb-0">
										<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Explore Visa Options</a></li>
										<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Explore Institutions</a></li>
									</ul>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<h6 className='mb-1'>About</h6>
								<ul className="list-unstyled mb-0">
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Our Mission</a></li>
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Careers</a></li>
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Blogs</a></li>
									<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Contact</a></li>
								</ul>
								<h6 className='mb-1 mt-4'>Get In Touch</h6>
								<ul className="list-unstyled mb-0">
								<li>
										<a href="mailto:info@visa24.app"><i className="fa fa-envelope mr-3 fs-12 text-secondary"></i> info@visa24.app</a>
									</li>
									<li>
										<a href="tel:+919817692020"><i className="fa fa-phone mr-3 text-secondary"></i> +91-9817692020</a>
									</li>
								</ul>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-12">
								<div>
									<h6 className='mb-1'>Resources</h6>
									<ul className="list-unstyled mb-0">
										<li><a href="#"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Resources</a></li>
									</ul>
								</div>
								<div className='mt-4'>
									<h6 className='mb-1'>Legal</h6>
									<ul className="list-unstyled mb-0">
										<li><NavLink to="/privacy"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Privacy Policy</NavLink></li>
										<li><NavLink to="/terms"><i className="fa fa-angle-double-right mr-2 text-secondary"></i> Terms &amp; Conditions</NavLink></li>
									</ul>
								</div>
							</div>
							<div className="col-xl-4 col-lg-6 col-md-12 d-none">
								<h6 className="mt-6 mt-xl-0">Get In Touch</h6>
								<ul className="list-unstyled mb-0">
									<li>
										<a href="#"><i className="fa fa-home mr-3 text-secondary"></i> New York, NY 10012, US-52014</a>
									</li>
									<li>
										<a href="#"><i className="fa fa-envelope mr-3 fs-12 text-secondary"></i> info12323@example.com</a>
									</li>
									<li>
										<a href="#"><i className="fa fa-phone mr-3 text-secondary"></i> + 01 234 567 88, + 01 234 567 88</a>
									</li>
									<li>
										<a href="#"><i className="fa fa-print mr-3 text-secondary"></i> + 01 234 567 89, + 01 234 567 89</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="bg-dark-purple text-white-50 p-3">
					<div className="container">
						<div className="row d-flex">
							<div className="col-lg-12 col-sm-12  mt-2 mb-2 text-center ">
								Copyright © 2020 <a href="#" className="fs-14 text-secondary">Gowell</a>. Designed by <a href="spruko.com" className="fs-14 text-secondary">Spruko</a> All rights reserved.
							</div>
						</div>
					</div>
				</div>
			</footer>
		</section>
	</>);
}

export default Footer;