import React, { useEffect, useState } from 'react';
import SearchResultItem from './searchResultItem/searchResultItem';
import HomeService from '../../apis/homeService';
import { useParams } from 'react-router';
import { useRecoilState } from 'recoil';
import { countriesList } from '../../recoilStates/countries';
import Slider from '../slider/slider';
import Header from '../header/header';

const SearchResuts = () => {
	const params = useParams();
	const [results, setResults] = useState([]);
	const [countries, setCountries] = useRecoilState(countriesList);

	const getCountries = () => {
		return new Promise((resolve)=>{
			if(countries.length==0) {
				HomeService.getCountries().then(res=>{
					setCountries(res);
					resolve(res);
				});
			}
			else {
				resolve(countries);
			}
		})
	}

	useEffect(()=>{
		getCountries().then((response)=> {
			HomeService.search(params).then(res=>{
				res.Data.Colleges.map(c=>{
					c.Type= 'college'
				});
				res.Data.Products.map(c=>{
					c.Type= 'product'
				});
				setResults([...res.Data.Colleges, ...res.Data.Products]);
				setTimeout(()=>{
					window.$(".search-result-item img").css({'height':document.querySelector('.search-result-item img').offsetWidth+'px'});
				}, 500);
			});
		});
	}, [params]);
    return ( <>
	<Header/>
	<Slider countries={countries} hideIcons={true}></Slider>
		<div className="cover-image sptb  py-9 bg-background d-none" data-image-src="../../assets/images/banners/banner1.jpg">
			<div className="header-text1 mb-0">
				<div className="container">
					<div className="row mt-4">
						<div className="col-xl-10 col-lg-12 col-md-12 d-block mx-auto">
							<div className="text-center text-white ">
								<h2 className="fs-40"><span className="font-weight-bold">96,365</span> Holiday Tours available</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
        

		<div className="bg-white border-bottom">
			<div className="container">
				<div className="page-header bg-transparent">
					<h4 className="page-title d-none">Results</h4>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#">Home</a></li>
						<li className="breadcrumb-item active" aria-current="page">Search Results</li>
					</ol>
				</div>
			</div>
		</div>
		
		<iframe className=" map-view" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15222.735906494796!2d78.5702658!3d17.4748335!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x326d1c90786b2ab6!2zU1BSVUtP4oSi!5e0!3m2!1sen!2sin!4v1600833029853!5m2!1sen!2sin" width="600" height="450"  style={{border: 0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

		<div className="bg-white border-bottom d-none">
			<div className="container">
				<div className="item2-gl business-list-01">
					<div className="">
						<div className="bg-white py-5 item2-gl-nav d-flex border-0 br-0 px-0">
							<div className="selectgroup">
								<label className="selectgroup-item mb-md-0">
									<input type="radio" name="value" value="All" className="selectgroup-input" checked=""/>
									<span className="selectgroup-button d-md-flex">All <i className="fa fa-sort ml-2 mt-1"></i></span>
								</label>
								<label className="selectgroup-item mb-md-0">
									<input type="radio" name="value" value="Distance" className="selectgroup-input"/>
									<span className="selectgroup-button">Famous</span>
								</label>
								<label className="selectgroup-item mb-0">
									<input type="radio" name="value" value="Latest" className="selectgroup-input"/>
									<span className="selectgroup-button">Latest</span>
								</label>
								<label className="selectgroup-item mb-0">
									<input type="radio" name="value" value="Rating" className="selectgroup-input"/>
									<span className="selectgroup-button">Rating</span>
								</label>
							</div>

							<ul className="nav item2-gl-menu ml-auto mt-1">
								<li className=""><a href="#tab-11" className="active show" data-toggle="tab" title="List style"><i className="fe fe-list"></i></a></li>
								<li><a href="#tab-12" data-toggle="tab" className="" title="Grid"><i className="fe fe-grid"></i></a></li>
							</ul>
							<a className="btn btn-light map-view-btn" href="#"><i className="fe fe-map-pin mr-1"></i><span className="active"> View On Map</span><span className="disactive"> Hide Map</span> </a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="sptb">
			<div className="container">
				<div className="row">
					{results.map((r,i)=>{
						return <SearchResultItem item={r} key={i}/>
					})}
                </div>
			</div>
		</div>
		
    </> );
}
 
export default SearchResuts;