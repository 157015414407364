import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import CollegeService from '../../apis/collegeService';
import Helper from '../../apis/helper';
import HomeService from '../../apis/homeService';
import ProductService from '../../apis/productService';
import URLs, { hostname } from '../../constants/urls';
import HomeCollegeCarousel from '../homeCollegeCarousel/homeCollegeCarousel';
import './productDetails.css';
import { useRecoilState } from 'recoil';
import { countriesList } from '../../recoilStates/countries';
import Header from '../header/header';

const ProductDetails = () => {
	const { type, id } = useParams();
	const [product, setProduct] = useState({ notInitialized: true });
	const pageName = (type === 'college' ? 'College' : 'Product') + ' Details';
	const category = (type === 'college' ? 'Colleges' : 'Products');
	const [colleges, setColleges] = useState([]);
	const [countries, setCountries] = useRecoilState(countriesList);

	const getCountries = () => {
		return new Promise((resolve)=>{
			if(countries.length==0) {
				HomeService.getCountries().then(res=>{
					setCountries(res);
					resolve(res);
				});
			}
			else {
				resolve(countries);
			}
		})
	}

	const apply = (productId, serviceId) => {
		console.log(productId, serviceId, type);
		Helper.redirectToAdminApp("/register/student?type="+type+"&product="+productId + "&service="+serviceId);
	}
	useEffect(() => {
		getCountries().then((response)=> {
			if (type === 'college') {
				CollegeService.getCollege(id).then(res => {
						res.Data.Country = Helper.getCountryNameFromCode(response, res.Data.Country);
						setProduct(res.Data);
						Helper.updateImageSources();
				});
				HomeService.getColleges().then(r => {
					r.Data.map(x=>{
						x.Country = Helper.getCountryNameFromCode(response, x.Country)
					})
					setColleges(r.Data);
				});
			}
			else {
				ProductService.getProduct(id).then(res => {
					res.Data.Country = Helper.getCountryNameFromCode(response, res.Data.Country);
					setProduct(res.Data);
					Helper.updateImageSources();
				})
			}
		});
		Helper.smoothScroll('#root')
	}, [id]);
	if (product.notInitialized) {
		return null;
	}
	return (<>
	<Header detailsPage={true}/>
		<div className="relative pt-10 pb-5 pattern2 bg-background-color bg-background-color-dark1 cover-image pb-9" data-image-src={hostname + product.CoverPhoto} style={{ background: "url('" + hostname + product.CoverPhoto + "')" }}>
			<div className="header-text1 mb-0">
				<div className="container">
					<div className="row">
						<div className="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
							<div className="text-center text-white p-8">
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="details-absolute">
				<div className="d-sm-flex container">
					<div><a href="#" className="text-white d-inline-block mr-4 "><i className="fe fe-map-pin text-white mr-2"></i>{product.Country}</a></div>
					<div className="m-auto text-white d-inline-block mr-4 "><i className="fe fe-map-pin invisible"></i>{product.Name}<i className="fe fe-map-pin invisible"></i></div>
					<div className="right">{type === 'college' && <a href="#" className="text-white d-inline-block mt-2 mt-sm-0"><i className="fe fe-calendar text-white mr-2 fs-14"></i>Courses Offered: {product.Courses.length}</a>}</div>
				</div>
			</div>
		</div>

		{/* <div className="text-center text-white">
		<h1 className="mb-0"><span className="font-weight-semibold college-title">{product.Name}</span></h1>
		</div> */}
		
		{type === 'college' && (!product.AdminActivated) ? <div className="text-center text-white"><h1 className="mb-0"><span className="font-weight-semibold college-title">This is Just a Preview</span></h1></div> : null}
		
		<div className="bg-white border-bottom d-none">
			<div className="container">
				<div className="page-header">
					<h4 className="page-title">{pageName}</h4>
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#">Home</a></li>
						<li className="breadcrumb-item"><a href="#">{category}</a></li>
						<li className="breadcrumb-item active" aria-current="page">{pageName}</li>
					</ol>
				</div>
			</div>
		</div>



		<section className="sptb pt-3">
			<div className="container">
				<div className="row">

					<div className="col-xl-4 col-lg-4 col-md-12">
						<div className="card overflow-hidden">
							<div className="card-header d-none">
								<h3 className="card-title">Tour Organizer</h3>
							</div>
							<div className="card-body item-user pb-0">
								<div className="profile-details">
									<div className="profile-pic mb-0 mx-5">
										<img src={hostname + product.ProfilePic} className="brround-no w-150 h-150" alt="user" />
									</div>
								</div>
								<div className="text-center mt-2">
									<a href="#" className="text-dark text-center"><h4 className="mt-0 mb-1 font-weight-semibold2">{product.Name}</h4></a>
									{ product.FoundedIn && <span className="text-muted mt-1"><b> Since {product.FoundedIn}</b></span>}
									{product.RegistrationNo && <div><small className="text-muted">Registration No. <b>{product.RegistrationNo}</b></small></div>}
								</div>
							</div>
							<div className="profile-user-tabs d-none">
								<div className="tab-menu-heading border-0 p-0">
									<div className="tabs-menu1">
										<ul className="nav">
											<li className=""><a href="#tab-contact" className="active" data-toggle="tab">Contact</a></li>
											<li><a href="#tab-timings" data-toggle="tab">New Tours</a></li>
										</ul>
									</div>
								</div>
							</div>
							{product.Type && <div>
								<h4 className="mt-0 mb-1 font-weight-semibold2 text-center">{product.Type}</h4>
							</div>}

							{(product.City && product.State) || product.Location ? <div className="tab-content border-0 bg-white">
								<div className="tab-pane active" id="tab-contact">
									<div className="card-body item-user">
										<h4 className="mb-4 d-none">Contact Info</h4>
										<div>
											{product.City && product.State ? <h6><span className="font-weight-semibold"><i className="fa fa-globe mr-3 mb-2"></i></span><a href="#" className="text-body"> {product.City}, {product.State}, {product.Country}</a></h6> : null}
											<h6 className="d-none"><span className="font-weight-semibold"><i className="fa fa-envelope mr-3 mb-2"></i></span><a href="#" className="text-body"> robert123@gmail.com</a></h6>
											<h6 className="d-none"><span className="font-weight-semibold"><i className="fa fa-phone mr-3 mb-2"></i></span>0-235-657-24587</h6>
											{product.Location ? <h6><span className="font-weight-semibold"><i className="fa fa-map mr-3 "></i></span><a href={product.Location} className="text-secondary" target="_blank">Location</a></h6> : null}
										</div>
									</div>
								</div>
								<div className="card-footer d-none">
									<div className="">
										<a href="#" className="btn btn-info mt-1 mb-1 mr-1"><i className="fa fa-envelope mr-1"></i> Chat</a>
										<a href="#" className="btn btn-secondary mt-1 mb-1 mr-1" data-toggle="modal" data-target="#contact"><i className="fa fa-user mr-1"></i> Contact Me</a>
									</div>
								</div>
							</div> : null}
						</div>

						<div className="card d-none">
							<div className="card-header">
								<h3 className="card-title">Details Shares Thorugh</h3>
							</div>
							<div className="card-body product-filter-desc">
								<div className="product-filter-icons text-center">
									<a href="#" className="facebook-bg"><i className="fa fa-facebook"></i></a>
									<a href="#" className="twitter-bg"><i className="fa fa-twitter"></i></a>
									<a href="#" className="google-bg"><i className="fa fa-google"></i></a>
									<a href="#" className="dribbble-bg"><i className="fa fa-dribbble"></i></a>
									<a href="#" className="pinterest-bg"><i className="fa fa-pinterest"></i></a>
								</div>
							</div>
						</div>
						<div className="card mt-5 d-none">
							<div className="card-body">
								<h4 className="font-weight-semibold2">Need Help for any Details?</h4>
								<div className="support-service bg-light br-5 mt-4">
									<i className="fa fa-phone bg-primary text-white"></i>
									<h6 className="text-default font-weight-bold mt-1">+68 872-627-9735</h6>
									<p className="text-default mb-0 fs-12">Free Support!</p>
								</div>
							</div>
						</div>
					</div>

					<div className="col-xl-8 col-lg-8 col-md-12">

						{product.Gallery && product.Gallery.length > 0 && <div className="card overflow-hidden">
							{/*Gallery*/}
							<div className="card-body">
								<div className="item-det mb-4">
									<a href="#" className="text-dark"><h3>Gallery</h3></a>
								</div>
								<div className="product-slider">
									<div id="carousel2" className="carousel slide" data-ride="carousel">
										<div className="carousel-inner">
											{product.Gallery.map((g,i)=>{
												return <div className={"carousel-item" + (i==0 ? " active":"")}><img src={URLs.attachment.view+g.ImageUrl} alt="img" /> </div>
											})}
										</div>
										<a className="carousel-control-prev" href="#carousel2" role="button" data-slide="prev">
											<i className="fa fa-angle-left" aria-hidden="true"></i>
										</a>
										<a className="carousel-control-next" href="#carousel2" role="button" data-slide="next">
											<i className="fa fa-angle-right" aria-hidden="true"></i>
										</a>
									</div>
									<div className="clearfix">
										<div id="thumbcarousel2" className="carousel slide product-slide-thumb" data-interval="false">
											<div className="carousel-inner">
												<div className="carousel-item active gallery-icons">
												{product.Gallery.map((g,i)=>{
													return <div data-target="#carousel2" data-slide-to={i} className="thumb mr-1"><img src={URLs.attachment.view+g.ImageUrl} alt="img" /></div>
												})}
												</div>
											</div>
											<a className="carousel-control-prev" href="#thumbcarousel2" role="button" data-slide="prev">
												<i className="fa fa-angle-left" aria-hidden="true"></i>
											</a>
											<a className="carousel-control-next" href="#thumbcarousel2" role="button" data-slide="next">
												<i className="fa fa-angle-right" aria-hidden="true"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>}

						<div className="card details-accordion">
							<div className="card-body">
								<div className="accordion" id="accordionExample">
								<div className="accordion" id="overviewAccordian">
									<div className="card">
										<a href="#" className="fs-16 font-weight-semibold2 collapsed card-header bg-transparent" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
											About
										</a>
										<div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#overviewAccordian">
											<div className="card-body leading-normal-2">
												<div className="mb-0">
													<p className="leading-loose ws-pw">{product.Info || product.Description}</p>

												</div>
											</div>
										</div>
									</div></div>
									{type === 'college' && <div className="accordion" id="featureAccordian"><div className="card">
										<a href="#" className="fs-16 font-weight-semibold2 card-header bg-transparent" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
											Features
										</a>
										<div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#featureAccordian">
											<div className="card-body leading-normal-2">
												<div className="table-responsive">
													<table className="table row table-borderless w-100 m-0 text-nowrap item-card2-desc">
														<tbody className="col-lg-12 col-xl-6 p-0">
															<tr>
																<td className="px-0"><i className={"fe d-inline-block mr-2 " + (product.Internship === 'Yes' ? ' fe-check text-success bg-success-transparent' : ' fe-x text-danger bg-danger-transparent')}></i>  Internship</td>
															</tr>
															<tr>
																<td className="px-0"><i className={"fe d-inline-block mr-2 " + (product.WorkWithStudy === 'Yes' ? ' fe-check text-success bg-success-transparent' : ' fe-x text-danger bg-danger-transparent')}></i>  Work With Study</td>
															</tr>
														</tbody>
														<tbody className="col-lg-12 col-xl-6 p-0">
															<tr>
																<td className="px-0"><i className={"fe d-inline-block mr-2 " + (product.ConditionalOfferLetter === 'Yes' ? ' fe-check text-success bg-success-transparent' : ' fe-x text-danger bg-danger-transparent')}></i>  Conditional Offer Letter</td>
															</tr>
															<tr>
																<td className="px-0"><i className={"fe d-inline-block mr-2 " + (product.Accomodation === 'Yes' ? ' fe-check text-success bg-success-transparent' : ' fe-x text-danger bg-danger-transparent')}></i>  Accomodation</td>
															</tr>
														</tbody>
														<tbody className="col-lg-12 col-xl-6 p-0">
															<tr>
																<td className="px-0"><i className={"fe d-inline-block mr-2 " + (product.RecruitInternationalStudents === 'Yes' ? ' fe-check text-success bg-success-transparent' : ' fe-x text-danger bg-danger-transparent')}></i>  Recruit International Students</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div></div>}

									{type === 'college' && <div className="accordion" id="financialAccordian"><div className="card">
										<a href="#" className="fs-16 font-weight-semibold2 card-header" data-toggle="collapse" id="headingSix" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
											Financials
										</a>
										<div id="collapseSix" className="collapse show" aria-labelledby="headingSix" data-parent="#financialAccordian">
											<div className="card-body leading-normal-2">
												<div className="table-responsive">
													<table className="table mb-0 table-bordered-0 text-nowrap">
														<tbody>
															<tr>
																<td className="font-weight-semibold px-0">Average Tution Fee</td>
																<td className="px-0">{product.AverageTutionFee} {product.CurrencyAbbr}</td>
															</tr>
															<tr>
																<td className="font-weight-semibold px-0">Cost of Living</td>
																<td className="px-0">{product.CostOfLiving} {product.CurrencyAbbr}</td>
															</tr>
															<tr>
																<td className="font-weight-semibold px-0">Health Cover Cost</td>
																<td className="px-0">{product.HealthCoverCost} {product.CurrencyAbbr}</td>
															</tr>
															<tr>
																<td className="font-weight-semibold px-0">Application Fee</td>
																<td className="px-0">{product.ApplicationFee} {product.CurrencyAbbr}</td>
															</tr>
															<tr className='top-border'>
																<td className="font-weight-semibold px-0">Estimated Total</td>
																<td className="px-0">{product.AverageTutionFee + product.CostOfLiving + product.HealthCoverCost + product.ApplicationFee} {product.CurrencyAbbr}</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div></div>}


									{product.Courses && product.Courses.map((course, i) => {
										return <div className="accordion" id={"courseAccordian"+i}><div className="card">
											<a href="#" className="fs-16 font-weight-semibold2 collapsed card-header bg-transparent course-accordian" id={"courseCollapsableHeader"+i} data-toggle="collapse" data-target={'#courseCollapsable' + i} aria-expanded="true" aria-controls="collapseTwo">
												{course.Name} {course.CourseId && ( '(' + course.CourseId + ')')}
												{product.AdminActivated && <button className='btn btn-info' onClick={()=>{apply(product.Id,course.Id)}}>Apply</button>}
											</a>
											<div id={'courseCollapsable' + i} className="collapse" aria-labelledby="headingTwo" data-parent={"#courseAccordian"+i}>
												<div className="card-body leading-normal-2">
													<div className="table-responsive">
														<table className="table mb-0 table-bordered-0 ws-pw">
															<tbody>
																<tr>
																	<td className="font-weight-semibold px-0">Course</td>
																	<td className="px-0">{course.Name} ({course.CourseId})</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Description</td>
																	<td className="px-0">{course.Description}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Admission Requirements</td>
																	<td className="px-0">{course.AdmissionRequirements}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Duration</td>
																	<td className="px-0">{course.Duration}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Intake Months</td>
																	<td className="px-0">{course.IntakeMonths1 && course.IntakeMonths1.split(',').join(' ')} {course.IntakeMonths2 && course.IntakeMonths2.split(',').join(' ')}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Fee</td>
																	<td className="px-0">{course.Fee} {product.CurrencyAbbr}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div></div>
									})}

									{product.ProductFeatures && product.ProductFeatures.length>0 && <div className="accordion" id="productFinancialAccordian"><div className="card">
										<a href="#" className="fs-16 font-weight-semibold2 card-header" data-toggle="collapse" id="headingSix" data-target="#productFinancollapseSix" aria-expanded="true" aria-controls="productFinancollapseSix">
											Financials &amp; Features
										</a>
										<div id="productFinancollapseSix" className="collapse show" aria-labelledby="headingSix" data-parent="#productFinancialAccordian">
											<div className="card-body leading-normal-2">
												<div className="table-responsive">
													<table className="table mb-0 table-bordered-0 text-nowrap">
														<tbody>
															{product.ProductFeatures.map(f=>{
																return <tr>
																			<td className="font-weight-semibold px-0">{f.Name}</td>
																			<td className="px-0">{f.Value}</td>
																		</tr>
															})}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div></div>}

									{product.ProductServices && product.ProductServices.map((productService, i) => {
										return <div className="accordion" id={"serviceAccordian"+i}><div className="card">
											<a href="#" className="fs-16 font-weight-semibold2 collapsed card-header bg-transparent course-accordian" id="headingTwo" data-toggle="collapse" data-target={'#productCollapsable' + i} aria-expanded="true" aria-controls="collapseTwo">
												{productService.Name} {productService.ServiceIdentity ? '(' +productService.ServiceIdentity+')' : ''}
												<button className='btn btn-info' onClick={()=>{apply(product.Id,productService.Id)}}>Apply</button>
											</a>
											<div id={'productCollapsable' + i} className="collapse" aria-labelledby="headingTwo" data-parent={"#serviceAccordian"+i}>
												<div className="card-body leading-normal-2">
													<div className="table-responsive">
														<table className="table mb-0 table-bordered-0 ws-pw">
															<tbody>
																<tr>
																	<td className="font-weight-semibold px-0">Service</td>
																	<td className="px-0">{productService.Name} ({productService.ServiceIdentity})</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Eligibility &amp; Requirements</td>
																	<td className="px-0">{productService.Requirements}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Duration</td>
																	<td className="px-0">{productService.Duration}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Intake Months</td>
																	<td className="px-0">{productService.IntakeMonths1 && productService.IntakeMonths1.split(',').join(' ')} {productService.IntakeMonths2 && productService.IntakeMonths2.split(',').join(' ')}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Fee</td>
																	<td className="px-0">{productService.Fee} {product.CurrencyAbbr}</td>
																</tr>
																<tr>
																	<td className="font-weight-semibold px-0">Service Fee</td>
																	<td className="px-0">{productService.ServiceFee} {product.CurrencyAbbr}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div></div>
									})}

									<div className="card mb-0 d-none">
										<a href="#" className="fs-16 font-weight-semibold2 collapsed card-header" data-toggle="collapse" id="headingSeven" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
											Tour Video
										</a>
										<div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
											<div className="card-body leading-normal-2">
												<iframe width="560" height="400" src="https://www.youtube.com/embed/kFjETSa9N7A" allow="accelerometer; autoplay;" allowfullscreen></iframe>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer d-none">
								<div className="icons">
									<a href="#" className="btn btn-primary px-5">Book This Tour</a>
									<a href="#" className="btn btn-light icons"><i className="fe fe-share-2 fs-16 leading-normal"></i></a>
									<a href="#" className="btn btn-light icons"><i className="fe fe-heart fs-16 leading-normal"></i></a>
									<a href="#" className="btn btn-light icons"><i className="fe fe-printer fs-16 leading-normal"></i></a>
									<a href="#" className="btn btn-light icons mb-1 mt-1" data-toggle="modal" data-target="#report"><i className="fe fe-info fs-16 leading-normal"></i></a>
								</div>
							</div>
						</div>

						{colleges && colleges.length > 0 && <><h3>Related Colleges</h3><HomeCollegeCarousel size={2} hideTitle={true} colleges={colleges}></HomeCollegeCarousel></>}

					</div>
				</div>
			</div>
		</section>



		{/* Footer */}



		<div className="modal fade" id="Comment" tabindex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Leave a Comment</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<input type="text" className="form-control" id="Comment-name" placeholder="Your Name" />
						</div>
						<div className="form-group">
							<input type="email" className="form-control" id="Comment-email" placeholder="Email Address" />
						</div>
						<div className="form-group mb-0">
							<textarea className="form-control" name="example-textarea-input" rows="6" placeholder="Message"></textarea>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-success">Send</button>
					</div>
				</div>
			</div>
		</div>


		<div className="modal fade" id="Map-modal" tabindex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Direction</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="h-400">

							<iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" className="h-100 w-100 border-0"></iframe>

						</div>
					</div>
				</div>
			</div>
		</div>


		<div className="modal fade" id="report" tabindex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="examplereportLongTitle">Report Abuse</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<input type="text" className="form-control" id="report-name" placeholder="Enter url" />
						</div>
						<div className="form-group">
							<select name="country" id="select-countries2" className="form-control custom-select select2-no-serach">
								<option value="1" selected>Categories</option>
								<option value="2">Spam</option>
								<option value="3">Identity Theft</option>
								<option value="4">Online Shopping Fraud</option>
								<option value="5">Service Providers</option>
								<option value="6">Phishing</option>
								<option value="7">Spyware</option>
							</select>
						</div>
						<div className="form-group">
							<input type="email" className="form-control" id="report-email" placeholder="Email Address" />
						</div>
						<div className="form-group mb-0">
							<textarea className="form-control" name="example-textarea-input" rows="6" placeholder="Message"></textarea>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-success">Submit</button>
					</div>
				</div>
			</div>
		</div>


		<div className="modal fade" id="contact" tabindex="-1" role="dialog" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="examplecontactLongTitle">Send Message</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="form-group">
							<input type="text" className="form-control" id="contact-name" placeholder="Your Name" />
						</div>
						<div className="form-group">
							<input type="email" className="form-control" id="contact-email" placeholder="Email Address" />
						</div>
						<div className="form-group mb-0">
							<textarea className="form-control" name="example-textarea-input" rows="6" placeholder="Message"></textarea>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
						<button type="button" className="btn btn-success">Send</button>
					</div>
				</div>
			</div>
		</div>


		<div className="modal fade" id="homeVideo" tabindex="-1" role="dialog">
			<div className="modal-dialog  modal-lg" role="document">
				<div className="modal-content">
					<button type="button" className="btn btn-default" data-dismiss="modal" onclick="pauseVid()"><i className="fe fe-x"></i></button>
					<div className="embed-responsive embed-responsive-16by9">
						<video id="gossVideo" className="embed-responsive-item" controls="controls">
							<source src="../../assets/video/1.mp4" type="video/mp4" />
						</video>
					</div>
				</div>
			</div>
		</div>


		<div className="modal fade" id="gallery" tabindex="-1" role="dialog">
			<div className="modal-dialog modal-lg" role="document">
				<div className="modal-content">
					<div className="product-slider modal-body p-2">
						<div id="carousel" className="carousel slide" data-ride="carousel">
							<a className="gallery-close-button" href="#" data-dismiss="modal" aria-label="Close"><i className="fe fe-x"></i></a>
							<div className="carousel-inner">
								<div className="carousel-item active"><img src="../../assets/images/places/1.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/2.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/3.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/4.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/5.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/1.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/2.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/3.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/4.jpg" alt="img" /> </div>
								<div className="carousel-item"><img src="../../assets/images/places/5.jpg" alt="img" /> </div>
							</div>
							<a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
								<i className="fa fa-angle-left" aria-hidden="true"></i>
							</a>
							<a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
								<i className="fa fa-angle-right" aria-hidden="true"></i>
							</a>
						</div>
						<div className="clearfix">
							<div id="thumbcarousel" className="carousel slide product-slide-thumb" data-interval="false">
								<div className="carousel-inner">
									<div className="carousel-item active">
										<div data-target="#carousel" data-slide-to="0" className="thumb"><img src="../../assets/images/places/1.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="1" className="thumb"><img src="../../assets/images/places/2.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="2" className="thumb"><img src="../../assets/images/places/3.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="3" className="thumb"><img src="../../assets/images/places/4.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="4" className="thumb"><img src="../../assets/images/places/5.jpg" alt="img" /></div>

									</div>
									<div className="carousel-item ">
										<div data-target="#carousel" data-slide-to="0" className="thumb"><img src="../../assets/images/places/1.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="1" className="thumb"><img src="../../assets/images/places/2.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="2" className="thumb"><img src="../../assets/images/places/3.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="3" className="thumb"><img src="../../assets/images/places/4.jpg" alt="img" /></div>
										<div data-target="#carousel" data-slide-to="4" className="thumb"><img src="../../assets/images/places/5.jpg" alt="img" /></div>
									</div>
								</div>
								<a className="carousel-control-prev" href="#thumbcarousel" role="button" data-slide="prev">
									<i className="fa fa-angle-left" aria-hidden="true"></i>
								</a>
								<a className="carousel-control-next" href="#thumbcarousel" role="button" data-slide="next">
									<i className="fa fa-angle-right" aria-hidden="true"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>);
}

export default ProductDetails;