import URLs from "../constants/urls";
import Networker from "./networker";

export default class HomeService {
  static getColleges() {
    return Networker.get(URLs.colleges.list);
  }
  static getProducts() {
    return Networker.get(URLs.products.list);
  }
  static getCountries() {
    return Networker.get(URLs.attachment.view + "contries.json");
  }
  static search(postData) {
    return Networker.post(URLs.home.search, postData);
  }
  static getCategories() {
    return Networker.get(URLs.home.categories);
  }
  static getBlogs() {
    return Networker.get(URLs.home.blogs);
  }
  static getBlog(id) {
    return Networker.get(URLs.home.blogs + "/" + id);
  }
}
