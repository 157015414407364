import React, { useEffect, useState } from 'react';
import Helper from '../../apis/helper';
import {useNavigate} from 'react-router-dom';
import { categoriesList } from '../../recoilStates/categories';
import HomeService from '../../apis/homeService';
import { useRecoilState } from 'recoil';


const Slider = ({countries, hideIcons}) => {
    let navigate = useNavigate();
    const [categories, setCategories] = useRecoilState(categoriesList);

    const searchResults = ()=>{
        const from = (Helper.getCountryCodeFromName(countries, document.getElementById('txtFrom').value)) || document.getElementById('txtFrom').value;
        const to = document.getElementById('drpTo').value;
        const purpose = document.getElementById('drpPurpose').value;
        if(!(from && to && purpose)) {
            return;
        }
        const searchOptions = [
            from,
            to,
            purpose,
        ];
        console.log(searchOptions);
        navigate(`/search/${searchOptions.join('/')}`)
    }
    
    useEffect(()=>{
        Helper.updateFlexListAndSelect2();
        Helper.updateImageSources()
    }, []);

    useEffect(() => {
        console.log(categories)
        HomeService.getCategories().then(res => {
            setCategories(res.Data);
        });
    }, []);

    return ( <>
    <section>
            <div className="banner-1 cover-image sptb-2 sptb-tab bg-background2" data-image-src="/assets/custom/images/banners/banner1.jpg">
                <div className="header-text mb-0">
                    <div className="container">
                        <div className="text-center text-white mb-4">
                            <h1 className="mb-3">Where Do you Want to Fly?</h1>
                            <p>Explore best visa options to suit your needs.</p>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 d-block mx-auto">
                                <div className="item-search-tabs travel-content">
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="tab1">
                                            <div className="search-background">
                                                <div className="form row no-gutters">
                                                    <div className="form-group col-xl-3 col-lg-3 col-md-12 mb-0">
                                                        <input type="text" id="txtFrom" className="flexdatalist form-control input-lg location-input border-right br-tr-0 br-br-0" data-min-length='1' list='countries' name='from' placeholder="Current Country" />
                                                        <datalist id="countries">
                                                            {countries.map((c,i)=>{
                                                                return <option key={i} value={c.id}>{c.name}</option>
                                                            })}
                                                        </datalist>
                                                        <span><img src="/assets/images/svgs/gps.svg" className="location-gps" alt="img" /></span>
                                                    </div>
                                                    <div className="form-group col-xl-3 col-lg-3 col-md-12 mb-0 select2-lg">
                                                        <select name="to" id="drpTo" className="form-control select2-show-search border-bottom-0 w-100">
                                                            <option>I'm Going to</option>
                                                            {countries.map((c,i)=>{
                                                                return <option key={i} value={c.id}>{c.name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-xl-6 col-lg-6 col-md-12 mb-0 location">
                                                        <div className="row no-gutters">
                                                            <div className="form-group col-xl-6 col-lg-6 col-md-12 mb-0 select2-lg border-right">
                                                                <select name="purpose" id="drpPurpose" className="form-control select2-show-search border-bottom-0 w-100">
                                                                    <option>Select Visa Type</option>
                                                                    {categories.map((item, ind)=>{
                                                                        return <option key={ind}>{item}</option>
                                                                    })}
                                                                </select>
                                                            </div>
                                                            
                                                            <div className="col-xl-6 col-lg-6 col-md-12 mb-0">
                                                                <button type="button" onClick={searchResults} className="btn btn-block btn-secondary btn-lg fs-14 br-tl-0 br-bl-0 shadow-none" >Search <i className="fe fe-arrow-right"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className={"col-xl-10 col-lg-12 col-md-12 mx-auto " + (hideIcons ? 'd-none' : 'd-block')}>
                                <div className="row mt-4">
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/hotel.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>Study Visa</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/restaurant.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>Tourist Visa</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/plane.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>Business Visa</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/vehicle.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>Work Visa</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/train.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>Airport Pickup</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/boat.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>Medical Visa</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <a className="p-3 tour-service">
                                            <img src="/assets/images/categories/wine.png" alt="img" className="w-6 h-6 mb-0 text-center mx-auto" />
                                            <h6>PR Visa</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                </div>
        </section>
    </> );
}
 
export default Slider;