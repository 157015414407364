import React from 'react';
import { NavLink } from 'react-router-dom';
import { hostname } from '../../../constants/urls';


const SearchResultItem = ({item}) => {
    return ( <>
					<div className="col-sm-12 col-lg-3 col-md-6">
						<div className="card gallery-item search-result-item">
                            <NavLink to={'/details/'+item.Type+'/'+ encodeURIComponent(item.Name)+'/'+item.Id}>
							<div className="text-center overflow-hidden mb-md-0">
								<div className="text-left card-body p-4">
									<div className="mb-4">
										<img style={{minWidth:200}} src={hostname + item.ProfilePic} alt="img" className="br-5"/>
									</div>
									<div className="d-flex border-bottom pb-3 mb-3 border-dashed border-top-0 border-left-0 border-right-0">
										<h4 className="mb-0 font-weight-semibold2">{item.Name}</h4>
										<div className="star-ratings start-ratings-main clearfix ml-auto d-none">
											<div className="stars stars-example-fontawesome stars-example-fontawesome-sm">
												<select className="example-fontawesome" name="rating" autocomplete="off">
													<option value="1">1</option>
													<option value="2">2</option>
													<option value="3">3</option>
													<option value="4" selected>4</option>
													<option value="5">5</option>
												</select>
											</div>
										</div>
									</div>
									<small className=""><i className="fe fe-map-pin"></i> {item.Country} </small>
									{/* <i className="ml-3 fe fe-eye"></i> 36+ Tour Places */}
								</div>
							</div>
                            </NavLink>
						</div>
					</div>
				
    </> );
}
 
export default SearchResultItem;