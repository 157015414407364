import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import HomeService from "../../apis/homeService";
import Header from "../header/header";
import "./blogDetails.css";

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  useEffect(() => {
    console.log("id", id);
    HomeService.getBlog(id).then((res) => {
      setBlog(res.Data);
    });
  }, []);
  return (
    <>
      <Header detailsPage={true} />
      {blog ? (
        <>
          <section class="bannerimg cover-image bg-background3">
            <div class="header-text mb-0">
              <div class="container">
                <div class="row text-white">
                  <div class="col">
                    <h1 class="">Blog Details</h1>
                  </div>
                  <div class="col col-auto">
                    <ol class="breadcrumb text-center">
                      <li class="breadcrumb-item">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li class="breadcrumb-item">
                        <NavLink to="/">Blog</NavLink>
                      </li>
                      <li
                        class="breadcrumb-item active text-white"
                        aria-current="page"
                      >
                        Blog Details
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="sptb">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body rich-text-content">
                      <a class="text-dark">
                        <h4 class="font-weight-semibold2 text-capitalize">
                          {blog.Title}
                        </h4>
                      </a>
                      <div dangerouslySetInnerHTML={{ __html: blog.Content }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default BlogDetails;
