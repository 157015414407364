import Header from "../header/header";
import Slider from "../slider/slider";

const Privacy = () => {
    return ( <>
        <Header detailsPage={true}/>
        <div className="container p-5">
                <div className="row">
                  <div className="col">
                    <h1 className="">Privacy Policy</h1>
                  </div>
                  </div>
                  <div className="row">
                    <div className="col">
                    <p className="content">Privacy Policy for Visa24 Immigration Visa Website
This Privacy Policy describes how Visa24 (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) collects, uses, and safeguards the
personal information you provide on the Visa24 immigration visa website (&quot;Website&quot;). We are
committed to protecting your privacy and ensuring the security of your personal information. Please
read this Privacy Policy carefully to understand how we handle your information.
<br/>
<br/>
<h4>Information We Collect</h4>
a. Personal Information: When you use our Website, we may collect personal information such as your
name, email address, phone number, passport details, and other relevant information necessary for
processing your visa application.<br/>
b. Usage Data: We may also collect non-personally identifiable information about your interactions with
our Website, including your IP address, browser type, operating system, referring URLs, and pages
visited. This information is collected automatically through cookies and similar technologies.
<br/>
<br/>
<h4>Use of Collected Information</h4>
a. We use the personal information you provide to process your visa application, communicate with you
regarding your application status, and provide the requested services.<br/>
b. We may use your email address to send you updates, newsletters, or other promotional materials
related to our services. You can opt-out of receiving such communications at any time by following the
unsubscribe instructions provided in the email.
<br/>
c. We may use aggregated usage data for statistical analysis, improving our services, and enhancing the
overall user experience.
<br/>
<br/>
<h4>Data Security</h4>
a. We employ industry-standard security measures to protect the personal information you provide to
us. However, please be aware that no data transmission or storage method is 100% secure. We cannot
guarantee the absolute security of your information.<br/>
b. We will retain your personal information only for as long as necessary to fulfill the purposes outlined
in this Privacy Policy, or as required by law.
<br/>
<br/>
<h4>Third-Party Service Providers</h4>
a. We may engage third-party service providers to assist us in operating and maintaining our Website,
such as hosting providers, payment processors, or customer support services. These providers may have
access to your personal information as necessary to perform their functions but are prohibited from
using it for any other purpose.<br/>
b. We may also share your personal information with relevant government agencies or authorities
involved in the visa application process as required by law.
<br/><br/>
<h4>Cookies and Tracking Technologies</h4>
a. Our Website may use cookies and similar tracking technologies to enhance your user experience,
analyze trends, and track user behavior. You can control the use of cookies through your browser
settings. However, disabling cookies may limit certain functionalities of the Website.
<br/><br/>
<h4>Third-Party Links</h4>
a. Our Website may contain links to third-party websites or services. We are not responsible for the
privacy practices or content of these third-party sites. We encourage you to review the privacy policies
of those sites before providing any personal information.
<br/><br/>
<h4>Children&#39;s Privacy</h4>
a. Our Website is not intended for use by individuals under the age of 18. We do not knowingly collect
personal information from children. If we become aware that we have inadvertently collected personal
information from a child under 18, we will take steps to delete such information.
<br/><br/>
<h4>Changes to the Privacy Policy</h4>
a. We reserve the right to modify or update this Privacy Policy at any time. Any changes to the Privacy
Policy will be posted on this page, with a revised effective date. We encourage you to review the Privacy
Policy periodically to stay informed about our data practices.
<br/><br/>
<h4>Contact Us</h4>
If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your
personal information, please contact us at [contact email].
<br/>
By using the Visa24 immigration visa website, you consent to the collection, use, and disclosure of your
personal information as outlined in this Privacy Policy.</p>
                    </div>
                  </div>
                  </div>
        
    </> );
}
 
export default Privacy;