import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Helper from "../../apis/helper";
import { hostname } from "../../constants/urls";

const BlogItem = ({ blog }) => {
  const [date, setDate] = useState("");
  useEffect(() => {
    setDate(new Date(blog.DateAdded).toDateString());
    Helper.updateImageSources();
  });
  return (
    <div className="col-xl-4 col-lg-3 col-md-6">
      <div
        className="card cover-image bg-background-color place-tour-card place-tour-card2 overflow-hidden"
        data-image-src={hostname + blog.CoverImage}
      >
        <NavLink className="absolute-link2" to={"/blog/" + blog.Id}></NavLink>
        <div className="card-body p-5 pb-0 text-white content-text place-blog">
          <h4 className="mt-2 mb-2 font-weight-semibold2 leading-normal">
            <NavLink to={"/blog/" + blog.Id} className="text-white">
              {blog.Title}
            </NavLink>
          </h4>
          <small className="text-warning font-weight-semibold2">
            <i className="fe fe-tag mr-1"></i>Blog
          </small>
          <small className="text-white ml-4">
            <i className="fe fe-calendar mr-1"></i> {date}
          </small>
          <small className="text-white ml-4 d-none">
            <i className="fe fe-user mr-1"></i> John Smith{" "}
          </small>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
