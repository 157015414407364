import React, { useEffect } from 'react';
import Helper from '../../apis/helper';
import CollegeCarouselItem from './collegeCarouselItem';
import './homeCollegeCarousel.css';

const HomeCollegeCarousel = ({colleges, size, hideTitle}) => {
    useEffect(()=>{
        var owl = window.$('.owl-carousel-icons2');
        owl.owlCarousel({
            padding: 20,
            loop: true,
            nav: true,
            autoplay:false,
            dots: false,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: ((size && size>2) || 4) - 2,
                    nav: true
                },
                1300: {
                    items: size || 4,
                    nav: true
                }
            }
        })
        Helper.updateImageSources();
        setTimeout(()=>{
            window.$(".college-carousel .place-tour-card").css({'height':document.querySelector('.college-carousel .place-tour-card').offsetWidth+'px'});
        }, 500);
    }, []);
    return (<><section className="sptb bg-white college-carousel">
    <div className="container">
        <div className="section-title center-block text-center">
            <span className="heading-style text-secondary d-none">World</span>
            <h2 className={hideTitle ? 'd-none' : ''}>Top Institutions Abroad</h2>
            <p className='d-none'>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
        </div>
        <div className="owl-carousel owl-carousel-icons2">
            {
                colleges.map((col,ind)=>{
                    return <CollegeCarouselItem key={ind} college={col}></CollegeCarouselItem>
                })
            }  
        </div>
    </div>
</section>
</>);
}
 
export default HomeCollegeCarousel;