import React, { useEffect, useState } from "react";
import AttractionsCarousel from "../attractionsCarousel/attractionsCarousel";
import Header from "../header/header";
import HomeCollegeCarousel from "../homeCollegeCarousel/homeCollegeCarousel";
import ServicesCarousel from "../servicesCarousel/servicesCarousel";
import Slider from "../slider/slider";
import HomeService from "../../apis/homeService";
import RatedCountries from "../ratedCountries/ratedCountries";
import HowItWorks from "../howItWorks/howItWorks";
import WhyChooseUs from "../whyChooseUs/whyChooseUs";
import CountCarousel from "../countCarousel/countCarousel";
import InfoCarousel from "../infoCarousel/infoCarousel";
import Footer from "../footer/footer";
import { useRecoilState } from "recoil";
import { countriesList } from "../../recoilStates/countries";
import Helper from "../../apis/helper";
import BlogSection from "../blogs/blogSection";
import config from "../../config";

const HomePage = () => {
  const [colleges, setColleges] = useState([]);
  const [products, setProducts] = useState([]);
  const [countries, setCountries] = useRecoilState(countriesList);

  useEffect(() => {
    getCountries().then((response) => {
      HomeService.getColleges().then((r) => {
        r.Data.map((x) => {
          x.Country = Helper.getCountryNameFromCode(response, x.Country);
        });
        setColleges(r.Data);
      });
      HomeService.getProducts().then((r) => {
        r.Data.map((x) => {
          x.Country = Helper.getCountryNameFromCode(response, x.Country);
        });
        setProducts(r.Data);
      });
    });
  }, []);

  const getCountries = () => {
    return new Promise((resolve) => {
      if (countries.length == 0) {
        HomeService.getCountries().then((res) => {
          setCountries(res);
          resolve(res);
        });
      } else {
        resolve(countries);
      }
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Slider countries={countries}></Slider>
      {colleges.length > 0 && (
        <HomeCollegeCarousel colleges={colleges}></HomeCollegeCarousel>
      )}
      <RatedCountries />
      <section className="sptb bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2>{config.appName} - {config.isAdmission24 ? "FASTEST WAY TO GET ADMISSION" : "The Fastest Way to Get Visa"}</h2>
              <p style={{ fontSize: "1rem" }}>
                {config.isAdmission24 ? <><b>Welcome to Australia’s first Mobile app for your all Admission needs.</b> Our App allows you to process your admission faster than ever before, Our Team of experts are always keen to assist you. We cater all your admission needs whether it is university, collage, or any other kind of institution. Just Search and secure your admission in Australia’s best institutions through our App and study in your dream institution. It’s very easy to apply through our App, just register your profile, provide all necessary info & upload supporting documents. Once you provide all docs & info, we cross check and process your admission request without wasting time. It’s easy & hassle-free.</> : <><b>
                  Welcome to India’s first fully online one stop shop for your
                  all visa needs.
                </b>{" "}
                  Our platform let you process your visa faster than ever before,
                  Team of immigration experts always keen to assist you. We cater
                  all your visa needs whether it is tourist visa, study visa,
                  business visa, permanent residency visa or any other kind of
                  visa. Just Explore and secure your admission from world’s best
                  institutions through our platform and study in your dream
                  destination abroad. Yes, it’s very easy to apply your visa
                  through our online platform just registers your profile provide
                  all necessary info & upload supporting documents that’s it. Once
                  you provide all docs & info our qualified visa experts cross
                  check and apply your visa within time. We call this process
                  Apply, supply and fly, just apply through our portal supply
                  supporting documents get your visa and fly, it’s easy.</>}

              </p>
            </div>
            <div className="col-md-6 offset-md-1">
              <img
                src="/assets/custom/images/easiestWay/easiestWay.jpg"
                alt="image"
              />
            </div>
          </div>
        </div>
      </section>

      {products.length > 0 ? (
        <ServicesCarousel products={products}></ServicesCarousel>
      ) : null}
      <HowItWorks />
      <WhyChooseUs />

      {/* <CountCarousel /> */}
      <InfoCarousel />
      <BlogSection />

      {/* <Footer /> */}
      <div className="modal fade" id="homeVideo" tabIndex="-1" role="dialog">
        <div className="modal-dialog  modal-lg" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="btn btn-default"
              data-dismiss="modal"
              onclick="pauseVid()"
            >
              <i className="fe fe-x"></i>
            </button>
            <div className="embed-responsive embed-responsive-16by9">
              <video
                id="gossVideo"
                className="embed-responsive-item"
                controls="controls"
              >
                <source src="/assets/video/1.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="gallery" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="product-slider modal-body p-2">
              <div
                id="carousel"
                className="carousel slide"
                data-ride="carousel"
              >
                <a
                  className="gallery-close-button"
                  href="#"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fe fe-x"></i>
                </a>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img src="/assets/images/places/1.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/2.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/3.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/4.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/5.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/1.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/2.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/3.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/4.jpg" alt="img" />{" "}
                  </div>
                  <div className="carousel-item">
                    <img src="/assets/images/places/5.jpg" alt="img" />{" "}
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carousel"
                  role="button"
                  data-slide="prev"
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carousel"
                  role="button"
                  data-slide="next"
                >
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
              <div className="clearfix">
                <div
                  id="thumbcarousel"
                  className="carousel slide product-slide-thumb"
                  data-interval="false"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div
                        data-target="#carousel"
                        data-slide-to="0"
                        className="thumb"
                      >
                        <img src="/assets/images/places/1.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="1"
                        className="thumb"
                      >
                        <img src="/assets/images/places/2.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="2"
                        className="thumb"
                      >
                        <img src="/assets/images/places/3.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="3"
                        className="thumb"
                      >
                        <img src="/assets/images/places/4.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="4"
                        className="thumb"
                      >
                        <img src="/assets/images/places/5.jpg" alt="img" />
                      </div>
                    </div>
                    <div className="carousel-item ">
                      <div
                        data-target="#carousel"
                        data-slide-to="0"
                        className="thumb"
                      >
                        <img src="/assets/images/places/1.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="1"
                        className="thumb"
                      >
                        <img src="/assets/images/places/2.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="2"
                        className="thumb"
                      >
                        <img src="/assets/images/places/3.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="3"
                        className="thumb"
                      >
                        <img src="/assets/images/places/4.jpg" alt="img" />
                      </div>
                      <div
                        data-target="#carousel"
                        data-slide-to="4"
                        className="thumb"
                      >
                        <img src="/assets/images/places/5.jpg" alt="img" />
                      </div>
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#thumbcarousel"
                    role="button"
                    data-slide="prev"
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#thumbcarousel"
                    role="button"
                    data-slide="next"
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chat-message-popup card mb-4 animated">
        <div className="popup-head">
          <div className="row">
            <div className="message-popup-left">
              <div className="dropdown">
                <a className="" href="" data-toggle="dropdown">
                  <i className="fe fe-more-horizontal text-white"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-left dropdown-menu-arrow">
                  <a href="#" className="dropdown-item">
                    <i className="fe fe-mail text-primary mr-1"></i> Send Mail
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="fe fe-thumbs-up text-primary mr-1"></i> Rate
                    as Good
                  </a>
                  <a href="#" className="dropdown-item">
                    <i className="fe fe-thumbs-down text-primary mr-1"></i> Rate
                    as Bad
                  </a>
                  <a href="#" className="dropdown-item">
                    <i className="fe fe-settings text-primary mr-1"></i>{" "}
                    Settings
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center font-weight-bold col-12 text-center">
              Chat With Us
            </div>
            <div className="message-popup-right text-right">
              <a
                className="card-options-fullscreen mr-2"
                href="#"
                data-toggle="card-fullscreen"
              >
                <i className="fe fe-maximize text-white"></i>
              </a>
              <a className="popup-minimize-normal" href="#">
                <i className="fe fe-x text-white"></i>
              </a>
              <a className="popup-minimize" href="#">
                <i className="fe fe-x text-white"></i>
              </a>
              <a className="popup-minimize-fullscreen" href="#">
                <i className="fe fe-x text-white"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="popup-chat-main-body">
          <div className="user-header p-3 border-top border-bottom">
            <div className="d-flex">
              <div className="d-flex">
                <img
                  className="avatar avatar-md brround mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
                <div>
                  <h6 className="mb-0 font-weight-bold">Rober Mitsubishi</h6>
                  <span className="w-2 h-2 brround bg-success d-inline-block mr-1"></span>{" "}
                  <small>active</small>
                </div>
              </div>
              <div className="ml-auto">
                <div className="chat-message-header-icons mt-1 fs-20">
                  <a className="mr-2" href="">
                    <i className="fe fe-volume-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-messages pt-0">
            <div className="direct-chat-messages">
              <div className="chat-box-single-line">
                <abbr className="timestamp">December 15th, 2018</abbr>
              </div>
              <div className="direct-chat-msg">
                <div className="direct-chat-text">
                  Hello. How are you today?
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg right">
                <div className="direct-chat-text">
                  Yes
                  <small className="time-text">10.00am</small>
                </div>
                <div className="direct-chat-text">
                  I'm fine. Thanks for asking fine. Thanks for asking!
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img"
                  src="/assets/images/faces/male/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="chat-box-single-line mt-5">
                <abbr className="timestamp">December 16th, 2018</abbr>
              </div>
              <div className="direct-chat-msg">
                <div className="direct-chat-text">
                  Various versions have evolved over the years, sometimes
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg right">
                <div className="direct-chat-text">
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img"
                  src="/assets/images/faces/male/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg">
                <div className="direct-chat-text">
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks as necessary
                  <small className="time-text">10.00am</small>
                </div>
                <div className="direct-chat-text">
                  making this the first true generator on the Internet
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg right">
                <div className="direct-chat-text">
                  <img
                    src="/assets/images/thumbnails/thumb2.jpg"
                    className="d-block"
                    alt="img"
                  />
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img"
                  src="/assets/images/faces/male/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="chat-box-single-line mt-5">
                <abbr className="timestamp">December 16th, 2018</abbr>
              </div>
              <div className="direct-chat-msg">
                <div className="direct-chat-text">
                  Various versions have evolved over the years, sometimes
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg right">
                <div className="direct-chat-text">
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text.
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img"
                  src="/assets/images/faces/male/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg">
                <div className="direct-chat-text">
                  <iframe
                    width="100"
                    height="250"
                    src="https://www.youtube.com/embed/kFjETSa9N7A"
                    allow="accelerometer; autoplay;"
                    allowFullScreen
                  ></iframe>
                  <small className="time-text">10.00am</small>
                </div>
                <div className="direct-chat-text">
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks as necessary
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg right">
                <div className="direct-chat-text">
                  <img
                    src="/assets/images/thumbnails/thumb2.jpg"
                    className="d-block"
                    alt="img"
                  />
                  <small className="time-text">10.00am</small>
                </div>
                <div className="direct-chat-text">
                  All the Lorem Ipsum generators on the Internet tend to repeat
                  predefined chunks as necessary
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg">
                <div className="direct-chat-text">
                  making this the first true generator on the Internet
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img mr-2"
                  src="/assets/images/faces/female/1.jpg"
                  alt="message user image"
                />
              </div>
              <div className="direct-chat-msg right">
                <div className="direct-chat-text">
                  <div className="d-flex">
                    <i className="fe fe-file-text fs-40 op-2 text-muted d-block mr-2"></i>
                    <div>
                      <div className="font-weight-bold fs-12">
                        sampledemo.txt
                      </div>
                      <span className="fs-12">4.5 kb</span>
                    </div>
                  </div>
                  <small className="time-text">10.00am</small>
                </div>
                <div className="direct-chat-text pb-6">
                  <div className="d-flex">
                    <div>
                      <img
                        src="/assets/images/thumbnails/thumb2.jpg"
                        className="m-1 w-8 h-8 br-2"
                        alt="img"
                      />
                    </div>
                    <div>
                      <img
                        src="/assets/images/thumbnails/thumb1.jpg"
                        className="m-1 w-8 h-8 br-2"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <img
                        src="/assets/images/thumbnails/thumb3.jpg"
                        className="m-1 w-8 h-8 br-2"
                        alt="img"
                      />
                    </div>
                    <div className="relative">
                      <img
                        src="/assets/images/thumbnails/thumb5.jpg"
                        className="m-1 w-8 h-8 br-2"
                        alt="img"
                      />
                      <div className="more-images">+10</div>
                    </div>
                  </div>
                  <small className="time-text">10.00am</small>
                </div>
                <img
                  className="direct-chat-img"
                  src="/assets/images/faces/male/1.jpg"
                  alt="message user image"
                />
              </div>
            </div>
          </div>
          <div className="popup-messages-footer card-footer p-0 bg-light">
            <textarea
              id="status_message"
              placeholder="Type a message..."
              rows="10"
              cols="40"
              name="message"
              className="form-control"
            ></textarea>
            <div className="chat-footer-icons">
              <a className="" href="#">
                <i className="fe fe-paperclip text-muted"></i>
              </a>
              <a className="" href="#">
                <i className="fe fe-send text-muted"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="popup-end-chat-main-body">
          <div className="p-6">
            <div className="section-end-chat text-center chat-content">
              <h2 className="font-weight-bold">End Chat?</h2>
              <p>
                All the Lorem Ipsum generators on the Internet tend to repeat
                predefined chunks as necessary
              </p>
              <div className="mt-6">
                <a className="btn btn-primary end-chat-button px-5" href="#">
                  Conform End Chat
                </a>
              </div>
              <div className="mt-3">
                <a
                  className="btn btn-link text-primary goback-chat btn-sm"
                  href="#"
                >
                  <i className="fe fe-arrow-left"></i> Go Back
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="rating-chat-main-body">
          <div className="p-6">
            <div className="text-left">
              <h3 className="font-weight-bold fs-20">
                Thank you for Contacting Us
              </h3>
              <h6>Please rate our supportive team in the following areas </h6>
              <form className="mt-5">
                <div className="mt-0">
                  <label>What is your best reason for your score</label>
                  <div className="star-ratings start-ratings-main clearfix">
                    <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                      <select
                        className="example-fontawesome"
                        name="rating"
                        autoComplete="off"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4" selected>
                          4
                        </option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label>What is your best reason for your score</label>
                  <div className="star-ratings start-ratings-main clearfix">
                    <div className="stars stars-example-fontawesome stars-example-fontawesome-sm mr-2">
                      <select
                        className="example-fontawesome"
                        name="rating"
                        autoComplete="off"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4" selected>
                          4
                        </option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <label>What is your best reason for your score</label>
                  <textarea
                    className="form-control"
                    rows="5"
                    cols="50"
                  ></textarea>
                </div>
                <a
                  className="btn btn-success px-5 mt-4 btn-chat-close"
                  href="#"
                >
                  Submit your Review
                </a>
              </form>
            </div>
          </div>
        </div>
      </div>

      <a href="#" id="chat-popup" className="chat-popup-active">
        <i className="fe fe-message-circle"></i>
      </a>
    </React.Fragment>
  );
};

export default HomePage;
