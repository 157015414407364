import React, { useEffect } from 'react';

const WhyChooseUs = () => {
	const images = [];
	for(var i=1; i<=8; i++) {
		images.push(<div class="item" key={i}>
		<div class="card mb-0">
			<div class="card-body p-0">
				<img src={"/assets/custom/images/whyChooseUs/"+i+".jpg"} alt="company1" class="w-100 mx-auto"/>
			</div>
		</div>
	</div>)
	}
    useEffect(()=>{
        var owl = window.$('.owl-carousel-icons2');
        owl.owlCarousel({
            padding: 20,
            loop: true,
            nav: true,
            autoplay:false,
            dots: false,
            lazyLoad: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                767: {
                    items: 2,
                    nav: true
                },
                1300: {
                    items: 4,
                    nav: true
                }
            }
        })
    })
    return ( <>
        <div class="sptb">
			<div class="container">
                <div className="section-title center-block text-center">
					<span className="heading-style text-secondary d-none">How</span>
					<h2>Why Choose Us?</h2>
				</div>
				<div  class="owl-carousel owl-carousel-icons2">
					{images}
				</div>
			</div>
		</div>
    </> );
}
 
export default WhyChooseUs;